import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Header, Icon, Message, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  AnalyticsAwareButton,
  ButtonDanger,
  ButtonTransparentDanger,
} from 'components/ui/button';

import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

const PaddedModalActions = styled(Modal.Actions)`
  &&& {
    padding: 1rem !important;
  }
`;

function WarningMessage({ relatedViewFacetNames }) {
  return (
    <Message warning>
      <Message.Header>
        <Trans id="group-create.group-used-in-analysis" />
      </Message.Header>
      <p>
        <Trans id="following-analysis-will-be-modified" />:
      </p>
      <ul>
        {relatedViewFacetNames.map(({ name }) => (
          <li key={`rvfn-${name}`}>{name}</li>
        ))}
      </ul>
      <p>
        <i style={{ fontWeight: styleVariables.fontWeightBold }}>
          <Icon name="circle warning" />
          <Trans id="analysis-without-product-group-will-be-deleted" />
        </i>
      </p>
    </Message>
  );
}

const relatedViewFacetNamesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ name: PropTypes.string })
);

WarningMessage.propTypes = {
  relatedViewFacetNames: relatedViewFacetNamesPropTypes.isRequired,
};

function SafeMessage() {
  return (
    <Message success>
      <Message.Header>
        <Trans id="group-can-be-delete-without-impact" />.
      </Message.Header>
      <p>
        <Trans id="no-analysis-related-to-this-group" />.
      </p>
    </Message>
  );
}

function ProductHierarchyGroupDeleteModal({
  open,
  productHierarchyGroup,
  relatedViewFacetNames,
  handleCloseModalOrClickOnConfirmDelete,
  deleteProductHierarchyGroup,
}) {
  const isGroupUsed = relatedViewFacetNames && relatedViewFacetNames.length;
  return (
    <Modal
      closeIcon
      onClose={handleCloseModalOrClickOnConfirmDelete}
      open={open}
    >
      <Header content={<Trans id="delete-product-service-group" />} />
      <Modal.Content>
        <div style={{ padding: `${styleVariables.spaceMedium} 0` }}>
          <Trans id="you-are-about-to-delete-product-service-group" />:
          <span
            style={{
              paddingLeft: styleVariables.spaceMediumLarge,
              fontWeight: styleVariables.fontWeightMedium,
              fontSize: styleVariables.fontSizeLarge,
            }}
          >
            &quot;{productHierarchyGroup.name}&quot;
          </span>
        </div>
        {isGroupUsed ? (
          <WarningMessage relatedViewFacetNames={relatedViewFacetNames} />
        ) : (
          <SafeMessage />
        )}
      </Modal.Content>
      <PaddedModalActions>
        <ButtonTransparentDanger
          onClick={handleCloseModalOrClickOnConfirmDelete}
        >
          <Trans id="cancel" />
        </ButtonTransparentDanger>
        <AnalyticsAwareButton
          inputComponent={ButtonDanger}
          gaCategory="V2"
          gaAction="Delete Product Group"
          gaLabel="From group list"
          onClick={() => {
            deleteProductHierarchyGroup(productHierarchyGroup.id);
            handleCloseModalOrClickOnConfirmDelete();
          }}
        >
          <Icon name="remove" />
          <Trans id="delete-group" />
        </AnalyticsAwareButton>
      </PaddedModalActions>
    </Modal>
  );
}

ProductHierarchyGroupDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  productHierarchyGroup: commonPropTypes.productHierarchyGroup,
  relatedViewFacetNames: relatedViewFacetNamesPropTypes,
  handleCloseModalOrClickOnConfirmDelete: PropTypes.func.isRequired,
  deleteProductHierarchyGroup: PropTypes.func.isRequired,
};

ProductHierarchyGroupDeleteModal.defaultProps = {
  relatedViewFacetNames: [],
  productHierarchyGroup: {},
};

export default ProductHierarchyGroupDeleteModal;
