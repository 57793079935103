import { useSelector } from 'react-redux';

import { msg, t } from '@lingui/macro';

import {
  useChannelLabelFormatter,
  useUserOrTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { formElementLabelFormatterSelectorFactory } from 'selectors/campaign';
import { tagColorFormatterSelector } from 'selectors/entities';
import {
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
} from 'selectors/survey';
import { campaignMonitoringHasScoreSelector } from 'store/monitor/monitorSlice';

import { EmptyPlaceholderValueCell } from 'components/ui/table/cells/ReactTableCell';

import {
  incrementFormatterFactory,
  onePrecisionFloatFormatter,
} from 'utils/formatter';
import { removeHtmlTags } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import { useSurveyQuestionsAlternativeDistributionOptions } from '../useCampaignFields';
import Kpi, { kpiAccessorFactory } from './chart/Kpi';
import QuestionsTable from './chart/QuestionsTable';
import {
  channelDistributionChartConfigFactory,
  distributionByChartConfigFactory,
  tagFormsChartConfigFactory,
  timeSeriesChartConfigFactory,
  volumeChartConfig,
} from './chart/commonChartConfiguration';

export const questionScoreChartConfig = () => ({
  title: t({ id: 'scores-per-question-title' }),
  component: QuestionsTable,
  accessor: (data) => data.meanScorePerQuestion,
  meta: {
    columns: [
      {
        accessor: 'title',
        id: 'id',
        title: t({ id: 'question-header' }),
      },
      {
        centered: true,
        accessor: 'averageScore',
        id: 'averageScore',
        title: t({ id: 'average-score-header' }),
        helpMessage: t({ id: 'average-score-header-help' }),
      },
    ],
  },
});

const formatIdValue = (id, questionLabelFormatter) => {
  const formattedLabels = (Array.isArray(id) ? id : [id])
    .map(questionLabelFormatter)
    .map(removeHtmlTags);
  return formattedLabels.join(', ') || '-';
};

export const surveyQuestionsChartConfigFactory = (
  questionAnswerLabelFormatter,
  questionLabelFormatter
) =>
  distributionByChartConfigFactory(
    (data) => data.questions,
    (item) => item?.value,
    'question-distribution-select',
    t({ id: 'question-distribution.no-data-message' }),
    questionAnswerLabelFormatter,
    null,
    useSurveyQuestionsAlternativeDistributionOptions,
    questionLabelFormatter,
    svars.competitionEntityColors[3],
    svars.competitionEntityColors[1],
    t({ id: 'answer' }),
    250,
    ''
  );

const getSurveyCampaignMonitoringDashboardConfig = (campaignId) => {
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const userOrTagLabelFormatter = useUserOrTagLabelFormatter();
  const formElementLabelFormatter = useSelector(
    formElementLabelFormatterSelectorFactory(campaignId)
  );
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId)
  );
  const questionAnswerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const hasScore = useSelector(campaignMonitoringHasScoreSelector);

  return [
    {
      row: 0,
      height: 3,
      children: [
        {
          row: 0,
          col: 0,
          width: 3,
          children: [
            {
              row: 0,
              col: '0-0',
              height: 1.5,
              ...volumeChartConfig,
            },
            {
              row: 0,
              col: '0-1',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t({ id: 'score' }),
              titleHelperHeader: t({ id: 'score-helper-header' }),
              titleHelper: t({ id: 'score-helper' }),
              accessor: kpiAccessorFactory('score'),
              meta: {
                color: svars.scoreColor,
                formatter: onePrecisionFloatFormatter,
                increaseFormatter: incrementFormatterFactory(
                  onePrecisionFloatFormatter
                ),
                emptyDataMessage: hasScore
                  ? msg({ id: 'campaign-monitoring.no-scored-answer' })
                  : msg({ id: 'campaign-monitoring.no-scored-configured' }),
              },
            },
          ],
        },
        {
          width: 7,
          row: 0,
          col: 1,
          ...timeSeriesChartConfigFactory({
            id: 'score',
            label: t({ id: 'score' }),
            accessor: 'score',
            Cell: EmptyPlaceholderValueCell,
            centered: true,
          }),
        },
        {
          row: 0,
          col: 2,
          width: 5,
          ...channelDistributionChartConfigFactory(channelLabelFormatter),
        },
      ],
    },
    {
      row: 1,
      height: 3,
      children: [
        ...(hasScore
          ? [
              {
                row: 1,
                col: 0,
                height: 3,
                width: 8,
                ...questionScoreChartConfig(),
                makeTableData: ({ data, meta }) => {
                  const columns = meta.columns.map((column) => ({
                    id: column.id,
                    label: column.title,
                  }));
                  const formattedData = data.map((row) => ({
                    ...row,
                    id: formatIdValue(row.id, questionLabelFormatter),
                  }));
                  return {
                    data: formattedData,
                    columns,
                  };
                },
                overrideToggleDataModal: true,
                overrideExportAsImage: true,
              },
            ]
          : []),
        {
          row: 1,
          col: 1,
          width: 8,
          ...surveyQuestionsChartConfigFactory(
            questionAnswerLabelFormatter,
            questionLabelFormatter
          ),
        },
        {
          row: 1,
          col: 2,
          width: 8,
          ...tagFormsChartConfigFactory(
            userOrTagLabelFormatter,
            formElementLabelFormatter,
            tagColorFormatter
          ),
        },
      ],
    },
  ];
};
export default getSurveyCampaignMonitoringDashboardConfig;
