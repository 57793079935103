import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { msg } from '@lingui/macro';
import PropTypes from 'prop-types';

import { conceptsSelector } from 'selectors/entities';
import { isFeedbackCampaignSelector } from 'selectors/survey';

import NavigationTabs from 'components/ui/navigation/NavigationTabs';

const APPEARANCE_PANE = {
  to: 'appearance',
  content: msg({ id: "appearance" }),
  disabled: false,
  pageRoute: '',
  icon: 'paint brush',
};

const HELP_PRIVACY_PANE = {
  to: 'privacy',
  content: msg({ id: "help-and-privacy" }),
  disabled: false,
  pageRoute: '',
  icon: 'question',
};

export const FEEDBACK_CUSTOMIZATION_PANES = [
  {
    to: 'general',
    content: msg({ id: "general" }),
    disabled: false,
    pageRoute: '',
  },
  {
    to: 'home',
    content: msg({ id: "home-page" }),
    disabled: false,
    pageRoute: '',
  },
  {
    to: 'search',
    content: msg({ id: "search-page" }),
    disabled: false,
    pageRoute: '/feedback',
  },
  {
    to: 'feedback-form',
    content: msg({ id: "feedback-form-page" }),
    disabled: false,
    pageRoute: '/feedback/',
  },
  {
    to: 'published',
    content: msg({ id: "published-page" }),
    disabled: false,
    pageRoute: '/published',
  },
  {
    to: 'identification-form',
    content: msg({ id: "identification-form" }),
    disabled: false,
    pageRoute: '/feedback/',
  },
  APPEARANCE_PANE,
  HELP_PRIVACY_PANE,
];

const useFeedbackCustomizationTabs = () => {
  const concepts = useSelector(conceptsSelector(null));
  const firstGeneralFeedbackConceptId = Object.keys(concepts)[0];
  return FEEDBACK_CUSTOMIZATION_PANES.map((pane) => ({
    ...pane,
    pageRoute: ['feedback-form', 'identification-form'].includes(pane.to)
      ? `/feedback/${firstGeneralFeedbackConceptId}`
      : pane.pageRoute,
  }));
};

export const SURVEY_CUSTOMIZATION_PANES = [
  {
    to: 'questions',
    content: msg({ id: "survey" }),
    disabled: false,
    pageRoute: '',
    icon: 'clipboard list',
  },
  APPEARANCE_PANE,
  HELP_PRIVACY_PANE,
];

const useSurveyCustomizationTabs = () =>
  SURVEY_CUSTOMIZATION_PANES.map((pane) => ({
    ...pane,
    content: pane.content,
  }));

const customizationPanesFactory = (useCampaignPanes) => {
  function PanesComponent({ campaignId, children }) {
    const panes = useCampaignPanes(campaignId);
    return (
      <NavigationTabs
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        panes={panes}
      >
        {children}
      </NavigationTabs>
    );
  }
  PanesComponent.propTypes = {
    campaignId: PropTypes.string.isRequired,
    children: PropTypes.node,
  };
  PanesComponent.defaultProps = {
    children: null,
  };

  return PanesComponent;
};

function CustomizationTabs({ campaignId, setActiveTab, children }) {
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const PanesComponent = useMemo(
    () =>
      customizationPanesFactory(
        isFeedbackCampaign
          ? useFeedbackCustomizationTabs
          : useSurveyCustomizationTabs
      ),
    [isFeedbackCampaign]
  );
  return (
    <PanesComponent campaignId={campaignId} setActiveTab={setActiveTab}>
      {children}
    </PanesComponent>
  );
}

CustomizationTabs.propTypes = {
  campaignId: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  children: PropTypes.node,
};

CustomizationTabs.defaultProps = {
  children: null,
};

export default CustomizationTabs;
