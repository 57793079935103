import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { actionTypes as campaignActionTypes } from 'actions/campaign';
import { loadingStateSelector } from 'reducers/ui';
import {
  campaignOntologyItemsSelectorFactory,
  campaignSatisfactionTagSetIdSelectorFactory,
  formElementUserItemsSelector,
  satisfactionTagItemsSelectorFactory,
} from 'selectors/campaign';
import { tagSetItemsSelectorFactory } from 'selectors/facet';
import { questionAnswerItemsSelector } from 'selectors/survey';
import {
  campaignFiltersSelector,
  displayFiltersSelector,
  oneFilterSelector,
  questionAnswerValuesSelector,
  resetFilters,
  resetQuestionFilters,
  satisfactionTagFilterValuesSelector,
  shouldUpdateSearchSelector,
  tagFilterValuesSelector,
  toggleFiltersPaneAndMaybeFetchFeedback,
  updateAnswersFilters,
  updateFilters,
  updateOneTagsFilter,
  updateTagsFilters,
} from 'store/monitor/monitorSearchSlice';

import { FORMS_ICON_MAP } from 'components/customer/campaign/campaignSubRoutes';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import SidePane from 'components/ui/panels/SidePane';
import tagSetFieldFilterFactory from 'components/ui/panels/tagSetFieldFilterFactory';
import FiltersAccordion from 'components/ui/search/FiltersAccordion';
import TextSearchFilter from 'components/ui/search/TextSearchFilter';
import staticFieldFilterFactory from 'components/ui/search/staticFieldFilterFactory';

import { useToggler } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import { OntologyFilterFactory } from '../../analysis/search/OntologyFilter';
import {
  useAnnotationForm,
  useCampaignFormFields,
  useIdentificationForm,
  useSurveyForm,
  useUrlForm,
} from '../useCampaignFields';
import CampaignChannelFilter from './CampaignChannelFilter';

function FeedbackCampaignFilters({
  filters,
  onUpdateFilters,
  setFilterModalIsActive,
  onResetFilters,
  onFilterToggle,
  isFilterActive,
}) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const campaignIsLoading = useSelector(
    loadingStateSelector([
      campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
    ])
  );
  const satisfactionTagSetId = useSelector(
    campaignSatisfactionTagSetIdSelectorFactory(campaignId)
  );
  const onUpdateOneTagFilters = useCallback(
    (feedbackFormValuesFieldName, tagSetId) =>
      ({ value: itemIds }) => {
        dispatch(
          updateOneTagsFilter({
            feedbackFormValuesFieldName,
            tagSetId,
            itemIds,
          })
        );
      },
    [updateTagsFilters]
  );

  const onResetOneTagFilters = useCallback(
    (filterKey, isTag = false, feedbackFormValuesFieldName = null) =>
      () =>
      () =>
        dispatch(
          resetFilters({
            filterKey: null,
            feedbackFormValuesFieldName,
            isTag,
          })
        ),
    []
  );

  const OntologyFilter = useMemo(
    () =>
      OntologyFilterFactory(
        campaignOntologyItemsSelectorFactory(campaignId),
        null,
        ({ value }, selectedItems) =>
          !!selectedItems.find((dbId) => dbId === value)
      ),
    [campaignId]
  );
  const SatisfactionTagFilter = useMemo(
    () =>
      tagSetFieldFilterFactory(
        satisfactionTagItemsSelectorFactory(campaignId),
        satisfactionTagFilterValuesSelector,
        onUpdateOneTagFilters,
        onResetOneTagFilters,
        campaignIsLoading
      ),
    [
      satisfactionTagFilterValuesSelector,
      onUpdateOneTagFilters,
      onResetOneTagFilters,
      campaignIsLoading,
    ]
  );

  return (
    <>
      <FiltersAccordion.Filter
        title={t({ id: 'keywords' })}
        nFilterValues={filters.textSearchValues?.length ? 1 : 0}
        onToggle={onFilterToggle('keyword')}
        isActive={isFilterActive('keyword')}
      >
        <TextSearchFilter
          onFilterChange={onUpdateFilters}
          textSearchValues={filters.textSearchValues}
          onSetFilterActive={setFilterModalIsActive}
        />
      </FiltersAccordion.Filter>
      <FiltersAccordion.Filter
        title={t({ id: 'concepts' })}
        nFilterValues={filters.concepts ? filters.concepts.length : 0}
        onToggle={onFilterToggle('concept')}
        isActive={isFilterActive('concept')}
      >
        <OntologyFilter
          hideAllConceptsToggle
          filtersKey="concepts"
          ontologyConcepts={filters.concepts}
          matchAllConcepts={filters.matchAllConcepts}
          onFilterChange={onUpdateFilters}
          onFilterReset={onResetFilters('concepts', false)}
        />
      </FiltersAccordion.Filter>
      <SatisfactionTagFilter
        itemId={satisfactionTagSetId}
        valueId={satisfactionTagSetId}
        key="test"
        header={t({ id: 'satisfaction-level' })}
        feedbackFormValuesFieldName="satisfaction_tag"
        setFilterModalIsActive={setFilterModalIsActive}
        isActive={isFilterActive('satisfaction_tag')}
        onToggle={onFilterToggle('satisfaction_tag')}
      />
    </>
  );
}

FeedbackCampaignFilters.propTypes = {
  filters: PropTypes.shape({
    textSearchValues: PropTypes.arrayOf(PropTypes.string),
    concepts: PropTypes.arrayOf(PropTypes.string),
    matchAllConcepts: PropTypes.bool,
  }).isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
  setFilterModalIsActive: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onFilterToggle: PropTypes.func.isRequired,
  isFilterActive: PropTypes.func.isRequired,
};

FeedbackCampaignFilters.defaultProps = {};

function SurveyCampaignFilters({
  onResetFilters,
  campaignIsLoading,
  setFilterModalIsActive,
  isFilterActive,
  onFilterToggle,
}) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const onUpdateAnswerFilters = useCallback(
    (_, questionId) =>
      ({ value: itemIds }) =>
        dispatch(updateAnswersFilters({ questionId, itemIds })),
    [updateAnswersFilters]
  );
  const onResetQuestionFilters = useCallback(
    (filterKey) => () => () =>
      dispatch(resetQuestionFilters({ questionId: filterKey })),
    []
  );

  const SurveyQuestionFilter = useMemo(
    () =>
      tagSetFieldFilterFactory(
        questionAnswerItemsSelector(campaignId),
        questionAnswerValuesSelector,
        onUpdateAnswerFilters,
        onResetQuestionFilters,

        campaignIsLoading
      ),
    [
      questionAnswerValuesSelector,
      onUpdateAnswerFilters,
      onResetFilters,
      campaignIsLoading,
    ]
  );
  const surveyQuestionFields = useCampaignFormFields({
    useSurveyForm,
    useSurveyTextQuestions: false,
  })[useSurveyForm];
  return surveyQuestionFields
    .filter(
      // Do not show filters for text form elements
      ({ type }) => type !== 'TextSurveyQuestion'
    )
    .map(({ id, ...props }) => (
      <SurveyQuestionFilter
        key={id}
        setFilterModalIsActive={setFilterModalIsActive}
        isActive={isFilterActive(id)}
        onToggle={onFilterToggle(id)}
        {...FORMS_ICON_MAP.surveyFormFields}
        {...props}
        itemId={id}
        valueId={id}
      />
    ));
}

SurveyCampaignFilters.propTypes = {
  onResetFilters: PropTypes.func.isRequired,
  campaignIsLoading: PropTypes.bool.isRequired,
  setFilterModalIsActive: PropTypes.func.isRequired,
  onFilterToggle: PropTypes.func.isRequired,
  isFilterActive: PropTypes.func.isRequired,
};

SurveyCampaignFilters.defaultProps = {};
function MonitoringFilters({
  isFeedbackCampaign,
  onValidateFilters,
  resetAllFilters,
  children,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  // toggle to true when a filter has an open modal, to lock the sidepane and
  // avoid that closing the modal closes the sidepane
  const [filterModalIsActive, setFilterModalIsActive] = useState(false);

  const shouldUpdateSearch = useSelector(shouldUpdateSearchSelector);
  const visible = useSelector(displayFiltersSelector);
  const filters = useSelector(campaignFiltersSelector);
  const campaignIsLoading = useSelector(
    loadingStateSelector([
      campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
    ])
  );

  const formFields = useCampaignFormFields(
    {
      useIdentificationForm,
      useUrlForm,
      useAnnotationForm,
    },
    null,
    null,
    // Do not show filters for text form elements
    true
  );
  const [isFilterActive, onFilterToggle] = useToggler(0);

  const onToggle = useCallback(
    () => dispatch(toggleFiltersPaneAndMaybeFetchFeedback({ campaignId })),
    [campaignId]
  );
  const onUpdateFilters = useCallback(
    (payload) => dispatch(updateFilters(payload)),
    []
  );
  const onUpdateTagsFilters = useCallback(
    (feedbackFormValuesFieldName, tagSetId) =>
      ({ value: itemIds }) => {
        dispatch(
          updateTagsFilters({
            feedbackFormValuesFieldName,
            tagSetId,
            itemIds,
          })
        );
      },
    [updateTagsFilters]
  );

  const onResetFilters = useCallback(
    (filterKey = null, isTag = false, feedbackFormValuesFieldName = null) =>
      () =>
      () => {
        dispatch(
          resetFilters({ filterKey, feedbackFormValuesFieldName, isTag })
        );
        // dispatch(setCampaignFilterPreset({ campaignId, presetId: null }));
      },
    []
  );

  const TagSetFieldFilter = useMemo(
    () =>
      tagSetFieldFilterFactory(
        tagSetItemsSelectorFactory,
        tagFilterValuesSelector,
        onUpdateTagsFilters,
        onResetFilters,

        campaignIsLoading
      ),
    [
      tagFilterValuesSelector,
      onUpdateTagsFilters,
      onResetFilters,
      campaignIsLoading,
    ]
  );
  const UserAnnotationFilter = useMemo(
    () =>
      tagSetFieldFilterFactory(
        (param2, param3) =>
          formElementUserItemsSelector(campaignId, param3, true),
        tagFilterValuesSelector,
        onUpdateTagsFilters,
        onResetFilters,

        campaignIsLoading
      ),
    [
      tagFilterValuesSelector,
      onUpdateTagsFilters,
      onResetFilters,
      campaignIsLoading,
    ]
  );
  const getFormFieldFilter = (tagSetId) =>
    tagSetId ? TagSetFieldFilter : UserAnnotationFilter;

  const StaticFilter = useMemo(
    () =>
      staticFieldFilterFactory(
        oneFilterSelector,
        onUpdateFilters,
        onResetFilters('channels', false),
        onFilterToggle,
        campaignIsLoading
      ),
    [onUpdateFilters, onResetFilters, campaignIsLoading, onFilterToggle]
  );
  return (
    <SidePane
      visible={visible}
      onToggle={onToggle}
      animation="push"
      direction="right"
      width="very wide"
      dimmed
      locked={filterModalIsActive}
    >
      <SidePane.Header
        title={
          <span
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Trans id="Filters" /> {children}
          </span>
        }
        onToggle={onToggle}
        gaCategory="Campaign - filter panel"
      />
      <SidePane.Body>
        <FiltersAccordion>
          <StaticFilter
            title={t({ id: 'campaign-channels' })}
            keys={['channels']}
            itemId="channels"
            FilterComponent={CampaignChannelFilter}
            onToggle={onFilterToggle('channels')}
            isActive={isFilterActive('channels')}
          />
          {isFeedbackCampaign ? (
            <FeedbackCampaignFilters
              filters={filters}
              onUpdateFilters={onUpdateFilters}
              setFilterModalIsActive={setFilterModalIsActive}
              onResetFilters={onResetFilters}
              formFields={formFields}
              onFilterToggle={onFilterToggle}
              isFilterActive={isFilterActive}
            />
          ) : null}
          {formFields
            ? Object.entries(formFields).map(([formLabel, fields], i) =>
                fields.map(
                  ({ tagSetId, id, feedbackFormValuesFieldName, ...props }) => {
                    const FilterComponent = getFormFieldFilter(tagSetId);
                    // If tagSetId is defined, use it as itemId, otherwise use id (form element id)
                    return (
                      <FilterComponent
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        setFilterModalIsActive={setFilterModalIsActive}
                        {...FORMS_ICON_MAP[formLabel]}
                        {...props}
                        feedbackFormValuesFieldName={
                          feedbackFormValuesFieldName
                        }
                        valueId={id}
                        itemId={tagSetId}
                        isActive={isFilterActive(id)}
                        onToggle={onFilterToggle(id)}
                      />
                    );
                  }
                )
              )
            : null}
          {!isFeedbackCampaign ? (
            <SurveyCampaignFilters
              onResetFilters={onResetFilters}
              campaignIsLoading={campaignIsLoading}
              setFilterModalIsActive={setFilterModalIsActive}
              onFilterToggle={onFilterToggle}
              isFilterActive={isFilterActive}
            />
          ) : null}
        </FiltersAccordion>
      </SidePane.Body>
      <SidePane.Actions visible>
        <AnalyticsAwareButton
          gaCategory="Campaign - search"
          gaAction="Reset filters"
          inputComponent={ButtonTransparentDanger}
          labelPosition="right"
          content={t({ id: 'reset-filters' })}
          onClick={resetAllFilters}
          icon="refresh"
          style={{ marginRight: svars.spaceMediumLarge }}
          nowrap="true"
        />
        <AnalyticsAwareButton
          gaCategory="Campaign - search"
          gaAction="Apply filters"
          inputComponent={ButtonAccent}
          labelPosition="right"
          content={t({ id: 'apply-filters' })}
          onClick={onValidateFilters}
          icon="filter"
          disabled={!shouldUpdateSearch}
          nowrap="true"
        />
      </SidePane.Actions>
    </SidePane>
  );
}
MonitoringFilters.propTypes = {
  isFeedbackCampaign: PropTypes.bool.isRequired,
  onValidateFilters: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  onTogglePresets: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default MonitoringFilters;
