import { msg } from '@lingui/macro';

import customizePageWordingFactory from './CustomizePageWording';

const FEEDBACK_HOME_WORDING = {
  title: msg({ id: "home-title" }),
  subtitle: msg({ id: "home-subtitle" }),
  secondSubtitle: msg({ id: "home-second-subtitle" }),
  ctaText: msg({ id: "home-cta-button" }),
  consultFAQPrefix: msg({ id: "you-have-questions?" }),
  consultFAQ: msg({ id: "check-faq" }),
};
export const CustomizeHomeTab = customizePageWordingFactory(
  'home',
  FEEDBACK_HOME_WORDING
);

const FEEDBACK_SEARCH_WORDING = {
  keyword_examples: msg({ id: "keyword-examples" }),
  title: msg({ id: "search-page-title" }),
  inputPlaceholder: msg({ id: "input-placeholder" }),
  noResults: msg({ id: "no-results" }),
  leaveQuickFeedback: msg({ id: "leave-quick-feedback" }),
  clearSearch: msg({ id: "clear-search" }),
  searchAConcept: msg({ id: "search-a-concept" }),
  searchAConceptSubtitle: msg({ id: "search-a-concept-subtitle" }),
};
export const CustomizeSearchTab = customizePageWordingFactory(
  'search',
  FEEDBACK_SEARCH_WORDING
);

const FEEDBACK_FORM_WORDING = {
  feedbackOn: msg({ id: "feedback-on" }),
  satisfactionLevelQuestion: msg({ id: "satisfaction-level-question" }),
  openTextQuestion: msg({ id: "open-text-question" }),
  openTextPlaceholder: msg({ id: "open-text-placeholder" }),
  emptyHelper: msg({ id: "empty-helper" }),
  privacyPolicy: msg({ id: "privacy-policy-title" }),
  privacyNoticePrefix: msg({ id: "privacy-noticeprefix" }),
  ctaText: msg({ id: "publish-feedback-cta-text" }),
};
export const CustomizeFeedbackFormTab = customizePageWordingFactory(
  'feedback',
  FEEDBACK_FORM_WORDING
);

const FEEDBACK_PUBLISHED_WORDING = {
  title: msg({ id: "title" }),
  subtitle: msg({ id: "subtitle" }),
  secondSubtitle: msg({ id: "second-subtitle" }),
  allConceptsClearedSubtitle: msg({ id: "all-concepts-cleared-subtitle" }),
  searchCtaText: msg({ id: "search-cta-text" }),
};
export const CustomizePublishedTab = customizePageWordingFactory(
  'published',
  FEEDBACK_PUBLISHED_WORDING
);
