import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist/index';

import { Trans, t } from '@lingui/macro';
import { Image, List } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  fetchNotifications,
  updateNotificationStatus,
} from 'store/monitor/notificationsSlice';

import NotificationCard from 'components/customer/notifications/Card';
import BeatingLoader from 'components/ui/BeatingLoader';
import Header, { LargeHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import { TabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip } from 'components/ui/icon/HoverableIconWithBadge';
import DeleteModal from 'components/ui/modal/DeleteModal';
import SidePane from 'components/ui/panels/SidePane';
import errorNotificationsUrl from 'components/ui/svg/undraw_bug_fixing_oc-7-a.svg';
import noNotificationsUrl from 'components/ui/svg/undraw_dreamer_re_9tua.svg';

import config from 'config';
import { StateStatus } from 'utils/apiSlice';

import * as svars from 'assets/style/variables';

import getStatusAndEndpoint from './utils';

const CenteredImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  min-height: ${svars.imageHeight};
  padding: ${svars.imagePadding};
`;

const NotificationList = styled(List)`
  &&&&& {
    margin-right: 15px;
  }
`;

function NoNotifications() {
  return (
    <CenteredImageContainer>
      <Image src={noNotificationsUrl} size="medium" />
      <LargeHeader>{t({ id: 'no-notifications' })}</LargeHeader>
      <div
        style={{
          fontSize: svars.fontSizeMedium,
          color: svars.colorGrey,
        }}
      >
        <Trans id="all-caught-up" />
      </div>
    </CenteredImageContainer>
  );
}

function ErrorFetchingNotifications() {
  return (
    <CenteredImageContainer>
      <Image src={errorNotificationsUrl} size="medium" />
      <LargeHeader>{t({ id: `error-fetching-notifications` })}</LargeHeader>
      <div
        style={{
          fontSize: svars.fontSizeMedium,
          color: svars.colorGrey,
        }}
      >
        <Trans>
          general-error-boundary.reach-support-for-emergency{' '}
          <Link base primary href={`mailto:${config.supportEmail}`}>
            {config.supportEmail}
          </Link>
        </Trans>
      </div>
    </CenteredImageContainer>
  );
}

function renderNotificationContent(
  notificationStatus,
  notifications,
  toggleSidePane
) {
  if (notificationStatus === StateStatus.PENDING) {
    return <BeatingLoader />;
  }

  if (notificationStatus === StateStatus.REJECTED) {
    return <ErrorFetchingNotifications />;
  }

  if (notifications.length === 0) {
    return <NoNotifications />;
  }

  return (
    <NotificationList>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          onToggle={toggleSidePane}
          {...notification}
        />
      ))}
    </NotificationList>
  );
}

export default function NotificationCenter() {
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationStatus = useSelector(
    (state) => state.notifications?.status
  );

  const notifications = useSelector(
    (state) => state.notifications?.notifications
  );
  const notificationIds = notifications.map((notification) => notification.id);
  const showBadge = notifications.some((notification) => !notification.seen);

  const closeModal = useCallback(() => setModalOpen(false), []);
  const toggleSidePane = () => {
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const handleNotificationAction = ({
    notificationId = null,
    links = {},
    markAsSeen = false,
    toggleSeenStatus = false,
    currentSeenStatus = false,
  }) => {
    const { status, callEndpoint } = getStatusAndEndpoint({
      markAsSeen,
      toggleSeenStatus,
      currentSeenStatus,
    });
    const ids = notificationId ? [notificationId] : notificationIds;

    dispatch(
      updateNotificationStatus({
        notificationIds: ids,
        seen: status,
        callEndpoint,
      })
    );

    if (navigate && (links.providedLink || links.generatedLink)) {
      navigate(links.providedLink || links.generatedLink);
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  return (
    <>
      <AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip
        onClick={toggleSidePane}
        name="bell"
        help={t({ id: `notifications` })}
        showBadge={showBadge}
      />
      <SidePane
        direction="right"
        visible={isSidePaneOpen}
        onToggle={toggleSidePane}
        width="very wide"
        animation="overlay"
        dimmed
      >
        <SidePane.Header
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Header nomargin="true">Notifications</Header>
              <TabButton
                icon="envelope open outline"
                content={t({ id: `mark-all-as-read` })}
                onClick={() => {
                  setModalOpen(true);
                }}
                disabled={
                  notificationStatus === 'failed' || notifications.length === 0
                }
              />
              <DeleteModal
                open={modalOpen}
                onDelete={() => {
                  handleNotificationAction({
                    markAsSeen: true,
                    currentSeenStatus: false,
                  });
                }}
                onClose={closeModal}
                headerText={t({ id: `mark-all-as-read-confirmation-text` })}
                confirmButtonText={t({
                  id: `mark-all-as-read-confirmation-button`,
                })}
                cancelButtonText={t({ id: `mark-all-as-read-cancel-button` })}
                contentText={t({
                  id: `mark-all-as-read-confirmation-context`,
                })}
                isDelete={false}
              />
            </div>
          }
          onToggle={toggleSidePane}
        />
        <SidePane.Body>
          {renderNotificationContent(
            notificationStatus,
            notifications,
            toggleSidePane
          )}
        </SidePane.Body>
      </SidePane>
    </>
  );
}
