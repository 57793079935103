const getStatusAndEndpoint = ({
  markAsSeen = false,
  toggleSeenStatus = false,
  currentSeenStatus = false,
}) => {
  let status = currentSeenStatus;
  let callEndpoint = false;

  if (toggleSeenStatus) {
    status = !currentSeenStatus;
    callEndpoint = true;
  } else if (markAsSeen && !currentSeenStatus) {
    status = true;
    callEndpoint = true;
  }

  return { status, callEndpoint };
};

export default getStatusAndEndpoint;
