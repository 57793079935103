import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {useLingui} from '@lingui/react';

import PropTypes from 'prop-types';

import { campaignSelector } from 'selectors/campaign';
import { isFeedbackCampaignConfiguration } from 'selectors/survey';
import { loadingChannelsSelector } from 'store/monitor/channelsSlice';
import { loadingSelector } from 'store/monitor/monitorSlice';
import useGetCampaignExportTitle from 'store/monitor/useGetCampaignExportTitle';

import BeatingLoader from 'components/ui/BeatingLoader';
import Dashboard from 'components/ui/dashboard/Dashboard';

import { useMemoizedFactorySelector } from 'utils/hooks';

import getFeedbackCampaignMonitoringDashboardConfig from './feedbackDashboardConfiguration';
import getSurveyCampaignMonitoringDashboardConfig from './surveyDashboardConfiguration';

const CampaignDashboardFactory = (useDashboardElements) => {
  function CampaignDashboard({ loading }) {
    const { campaignId } = useParams();
    const elements = useDashboardElements(campaignId);

    return (
      <Dashboard
        loading={loading}
        elements={elements}
        getExportTitle={useGetCampaignExportTitle(campaignId)}
      />
    );
  }
  CampaignDashboard.propTypes = {
    loading: PropTypes.bool.isRequired,
  };

  return CampaignDashboard;
};

const FeedbackCampaignMonitoringDashboard = CampaignDashboardFactory(
  getFeedbackCampaignMonitoringDashboardConfig
);

const SurveyCampaignMonitoringDashboard = CampaignDashboardFactory(
  getSurveyCampaignMonitoringDashboardConfig
);

function MonitoringDashboard() {
  useLingui();
  const { campaignId } = useParams();

  const monitoringIsloading = useSelector(loadingSelector);
  const channelsAreLoading = useSelector(loadingChannelsSelector);
  const campaignConfiguration = useMemoizedFactorySelector(
    campaignSelector,
    campaignId
  );
  const isFeedbackCampaign = isFeedbackCampaignConfiguration(
    campaignConfiguration
  );
  const DashboardComponent = useMemo(
    () =>
      isFeedbackCampaign
        ? FeedbackCampaignMonitoringDashboard
        : SurveyCampaignMonitoringDashboard,
    [isFeedbackCampaign]
  );
  return (
    ((monitoringIsloading || channelsAreLoading) && <BeatingLoader />) || (
      <DashboardComponent loading={monitoringIsloading || channelsAreLoading} />
    )
  );
}

export default MonitoringDashboard;
