import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';

import { maybeFetchProductHierarchyGroups } from 'actions/entity_groups';
import {
  actionTypes,
  deleteViewFacet,
  fetchViewFacets,
  setViewFacetAsFavorite,
} from 'actions/facet';
import { toggleDisplayFavouritesOnly } from 'actions/home';
import { maybeFetchViews } from 'actions/view';
import withPolling from 'actions/withPolling';
import { createLoadingSelector } from 'reducers/ui';

import { enrichFieldProps } from 'components/customer/campaign/useCampaignFields';
import Link from 'components/ui/Link';
import ManagementList from 'components/ui/ManagementList';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';
import DeleteModal from 'components/ui/modal/DeleteModal';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import viewFacetRowFactory from './ViewFacetList';

function EmptyListActions({ resetFilters }) {
  return (
    <Trans>
      <Link
        base="true"
        onClick={resetFilters}
        style={{ marginRight: svars.spaceNormal }}
      >
        Réinitialiser les filtres
      </Link>
      pour afficher toutes les analyses.
    </Trans>
  );
}

EmptyListActions.propTypes = { resetFilters: PropTypes.func.isRequired };

const tableFields = [
  enrichFieldProps(
    { accessor: 'name', label: msg({ id: 'analysis-name' }), width: 85 },
    false
  ),
  enrichFieldProps(
    {
      accessor: 'aggregates_update_date',
      label: msg({ id: 'updated-on' }),
      width: 45,
      sortType: 'basic',
    },
    false,
    { isDate: true }
  ),
  enrichFieldProps(
    {
      accessor: 'favorite',
      // Do not show the label
      label: null,
      width: 5,
      sortType: 'basic',
    },
    false
  ),
  enrichFieldProps({ accessor: null, label: null, width: 30 }, false),
];
function ViewFacetListPage({
  viewFacets,
  displayFavoritesOnly,
  onToggleDisplayFavouritesOnly,
  loading,
}) {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const [showDetailsViewFacetIds, setShowDetailsViewFacetIds] = useState([]);
  const [viewFacetToDelete, setViewFacetToDelete] = useState(undefined);
  useEffect(() => {
    dispatch(maybeFetchViews());
    dispatch(maybeFetchProductHierarchyGroups());
  }, []);

  const handleCloseDeleteModal = useCallback(
    () => setViewFacetToDelete(undefined),
    []
  );

  const onShowViewFacetDetails = useCallback(
    (viewFacetId) => () => {
      const newActiveIds = [...showDetailsViewFacetIds];
      const inActiveIdIndex = showDetailsViewFacetIds.findIndex(
        (activeIndice) => activeIndice === viewFacetId
      );
      if (inActiveIdIndex === -1) {
        newActiveIds.push(viewFacetId);
      } else {
        newActiveIds.splice(inActiveIdIndex, 1);
      }
      setShowDetailsViewFacetIds(newActiveIds);
    },
    [showDetailsViewFacetIds]
  );

  const onViewFacetDelete = useCallback(
    (viewFacet) => () => setViewFacetToDelete(viewFacet),
    []
  );

  const onSetViewFacetAsFavorite = useCallback(
    (id, favorite) => () => {
      dispatch(setViewFacetAsFavorite(id, favorite));
    },
    []
  );
  const renderViewFacetRow = useMemo(
    () =>
      viewFacetRowFactory(
        showDetailsViewFacetIds,
        onViewFacetDelete,
        onSetViewFacetAsFavorite,
        onShowViewFacetDetails
      ),
    [
      i18n.locale,
      showDetailsViewFacetIds,
      onViewFacetDelete,
      onSetViewFacetAsFavorite,
      onShowViewFacetDetails,
    ]
  );

  const handleDelete = useCallback(
    () => dispatch(deleteViewFacet(viewFacetToDelete.id)),
    [dispatch, viewFacetToDelete]
  );

  return (
    <PageLayout simple padded>
      <DeleteModal
        open={viewFacetToDelete}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
        headerText={t({ id: 'confirm-analysis-delete' })}
        contentText={
          <Trans>
            analysis-deletion.confirm-modal-message-
            <b>{viewFacetToDelete?.name}</b>
          </Trans>
        }
        confirmWithInputValue={t({ id: 'to-delete-analysis' })}
      />
      <ManagementList
        loading={loading && !viewFacets}
        nActions={1}
        rowFields={tableFields}
        items={viewFacets}
        displayFavoritesOnly={displayFavoritesOnly}
        onToggleDisplayFavouritesOnly={onToggleDisplayFavouritesOnly}
        defaultSorted={{
          key: 'aggregates_update_date',
          ascending: false,
        }}
        renderItemRow={renderViewFacetRow}
        textFilterPlaceholder={t({ id: 'search-an-analysis' })}
        emptyListHeader={<Trans id="no-analysis-found" />}
        EmptyListContent={EmptyListActions}
        actions={
          <RouterLink to="/facets/create">
            <AnalyticsAwareButton
              gaCategory="V2"
              gaAction="Add Facet"
              gaLabel="Add Facet - from View Facets List"
              inputComponent={ButtonAccent}
              type="button"
              icon="plus"
              labelPosition="right"
              content={t({ id: 'new-analysis' })}
              data-testid="bo-new-analysis-button"
            />
          </RouterLink>
        }
      />
    </PageLayout>
  );
}

ViewFacetListPage.propTypes = {
  viewFacets: PropTypes.arrayOf(commonPropTypes.viewFacet),
  displayFavoritesOnly: PropTypes.bool,
  onToggleDisplayFavouritesOnly: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ViewFacetListPage.defaultProps = {
  viewFacets: undefined,
  displayFavoritesOnly: false,
};

const loadingSelector = createLoadingSelector([
  actionTypes.FETCH_VIEW_FACETS_REQUEST,
]);

const ViewFacetListPageWithFacetListPolling = withPolling(
  fetchViewFacets,
  15000
)(ViewFacetListPage);

export default connect(
  (state) => ({
    viewFacets: state.facet.viewFacets,
    loading: loadingSelector(state),
    displayFavoritesOnly: state.home.displayFavoritesOnly,
  }),
  (dispatch) => ({
    onToggleDisplayFavouritesOnly: () =>
      dispatch(toggleDisplayFavouritesOnly()),
  })
)(ViewFacetListPageWithFacetListPolling);
