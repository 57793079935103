import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form, Grid, Header, Icon, Message, Modal } from 'semantic-ui-react';

import {
  actionTypes as entitiesActionTypes,
  maybeFetchProductHierarchies,
} from 'actions/entities';
import {
  createProductHierarchyGroup,
  actionTypes as entityGroupsActionTypes,
  updateProductHierarchyGroup,
} from 'actions/entity_groups';
import { createLoadingSelector } from 'reducers/ui';

import { MediumPaddedSegment } from 'components/ui/Segment';
import LightText from 'components/ui/Text';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import { HierarchicalCheckboxList } from 'components/ui/inputs/checkbox-list';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const productHierarchiesLoadingSelector = createLoadingSelector([
  entitiesActionTypes.FETCH_PRODUCT_HIERARCHIES_REQUEST,
]);

const productHierarchyGroupsLoadingSelector = createLoadingSelector([
  entityGroupsActionTypes.CREATE_PRODUCT_HIERARCHY_GROUPS_REQUEST,
  entityGroupsActionTypes.UPDATE_PRODUCT_HIERARCHY_GROUPS_REQUEST,
]);

const createOrUpdateGroup =
  (dispatch) =>
  async (id, name, productHierarchyItems, onSuccess = null, onError = null) => {
    const baseParams = {
      name,
      productHierarchies: productHierarchyItems,
    };
    let actionPromise;
    if (id) {
      actionPromise = dispatch(
        updateProductHierarchyGroup({
          id,
          ...baseParams,
        })
      );
    } else {
      actionPromise = dispatch(createProductHierarchyGroup(baseParams));
    }
    actionPromise.then((result) => {
      if (onSuccess && /SUCCESS/i.exec(result.type)) {
        onSuccess(result);
      } else if (onError && /FAILURE/i.exec(result.type)) {
        onError(result);
      }
    });
  };
function FormAction({
  initialGroup,
  disabled,
  onCreateOrUpdateGroup,
  loading,
}) {
  const commonProps = {
    gaCategory: 'V2',
    gaLabel: 'From Product Group List',
    type: 'button',
    inputComponent: ButtonAccent,
    labelPosition: 'right',
    disabled,
    onClick: !loading ? onCreateOrUpdateGroup : null,
  };
  const contextProps = initialGroup
    ? {
        gaAction: 'Update Product Group',
        icon: 'setting',
        content: <Trans id="modify-group" render={capitalizedTranslation} />,
      }
    : {
        gaAction: 'Add Product Group',
        icon: 'plus',
        content: <Trans id="create-group" render={capitalizedTranslation} />,
      };
  return initialGroup ? (
    <AnalyticsAwareButton {...commonProps} {...contextProps} />
  ) : (
    <AnalyticsAwareButton
      data-testid="bo-create-group-button"
      {...commonProps}
      {...contextProps}
      loading={loading}
    />
  );
}

FormAction.propTypes = {
  disabled: PropTypes.bool,
  initialGroup: commonPropTypes.productHierarchyGroup,
  onCreateOrUpdateGroup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

FormAction.defaultProps = {
  initialGroup: undefined,
  disabled: false,
};

function ProductHierarchyGroupCreateModal({
  initialGroup,
  onClose,
  open,
  relatedViewFacetNames,
}) {
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState(initialGroup?.name || '');
  const [selectedItems, setSelectedItems] = useState(initialGroup?.items || []);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(null);
  const [hideWarning, setHideWarning] = useState(false);

  const productHierarchiesItems = useSelector(
    (state) => state.entities.productHierarchiesItems
  );
  const productHierarchiesLoading = useSelector(
    productHierarchiesLoadingSelector
  );
  const productHierarchyGroupsLoading = useSelector(
    productHierarchyGroupsLoadingSelector
  );

  useEffect(() => {
    dispatch(maybeFetchProductHierarchies());
  }, []);

  const dismissWarning = () => setHideWarning(true);

  const onCreateOrUpdateGroup = useCallback(() => {
    createOrUpdateGroup(dispatch)(
      initialGroup ? initialGroup.id : null,
      groupName,
      selectedItems,
      (result) => {
        onClose(result?.productHierarchyGroup?.id);
      },
      () => setDisplayErrorMessage(true)
    );
  }, [dispatch, initialGroup, groupName, selectedItems, onClose]);

  const onSelectItem = useCallback(
    (item) => {
      setSelectedItems([...selectedItems, item.value]);
    },
    [selectedItems]
  );
  const onUnselectItem = useCallback(
    (item) => {
      setSelectedItems(selectedItems.filter(({ id }) => id !== item.key));
    },
    [selectedItems]
  );
  const onSelectItems = useCallback(
    (itemsToAdd) => {
      setSelectedItems([
        ...selectedItems,
        ...itemsToAdd.map(({ value }) => value),
      ]);
    },
    [selectedItems]
  );
  const onResetItems = useCallback(() => setSelectedItems([]), [selectedItems]);
  const onUnselectItems = useCallback(
    (itemsToRemove) => {
      const values = itemsToRemove.map(({ key }) => key);
      setSelectedItems(selectedItems.filter(({ id }) => !values.includes(id)));
    },
    [selectedItems]
  );
  const isSelectedItem = useCallback(
    (item, newSelectedItems) =>
      (newSelectedItems || []).some(
        (selectedItem) => selectedItem.id === item.key
      ),
    []
  );

  const createOrModify = capitalize(
    initialGroup ? t({ id: 'modify' }) : t({ id: 'create' })
  );
  const actionDisabled =
    !groupName.length || !selectedItems.length || displayErrorMessage;
  return (
    <Modal closeIcon onClose={() => onClose(undefined)} open={open}>
      <Modal.Header
        content={capitalize(
          t`${createOrModify} un groupe de produit(s) / service(s)`
        )}
      />
      <Modal.Content>
        <Grid
          style={{
            padding: `${svars.spaceSmall} ${svars.spaceLarge}`,
          }}
        >
          {relatedViewFacetNames.length && !hideWarning ? (
            <Grid.Row>
              <Grid.Column>
                <Message warning onDismiss={dismissWarning}>
                  <Message.Header>
                    <Trans id="group-create.group-used-in-analysis" />.
                  </Message.Header>
                  <p>
                    <Trans id="group-create.group-used-in-following-analysis" />
                    :
                  </p>
                  <ul>
                    {relatedViewFacetNames.map(({ name }) => (
                      <li key={`wml-${name}`}>{name}</li>
                    ))}
                  </ul>
                  <p>
                    <Trans id="group-create.when-updating-group-analysis-are-updated" />
                  </p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          <Grid.Row as={MediumPaddedSegment}>
            <Grid.Column width={6} verticalAlign="bottom">
              <Header as="h4">
                <Trans id="product-service-group-name" />
              </Header>
            </Grid.Column>
            <Grid.Column width={10} verticalAlign="bottom">
              <Form>
                <LimitedTextInput
                  error={displayErrorMessage}
                  style={{
                    fontWeight: svars.fontWeightBold,
                    fontSize: svars.fontSizeXLarge,
                  }}
                  data-testid="bo-facility-group-1-button"
                  placeholder={capitalize(
                    t({ id: 'product-service-group-name-placeholder' })
                  )}
                  value={groupName}
                  onChange={(e, { value }) => {
                    setGroupName(value);
                    setDisplayErrorMessage(false);
                  }}
                  maxCharacters={svars.hierarchyGroupNameMaxCharacters}
                />
              </Form>
              {displayErrorMessage ? (
                <div
                  style={{
                    color: svars.colorDanger,
                    paddingTop: svars.spaceNormal,
                  }}
                >
                  <Trans id="group-name-already-used" />
                </div>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered as={MediumPaddedSegment} style={{ marginTop: 0 }}>
            <Grid.Column width={16}>
              <Header as="h4">
                <Trans id="products-services" />
                <Header.Subheader>
                  <Trans id="products-services-group-content-description" />
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={16} style={{ paddingTop: svars.spaceMedium }}>
              <HierarchicalCheckboxList
                style={{
                  padding: svars.spaceNormal,
                  height: '45vh',
                  boxShadow: svars.baseBoxShadow,
                  borderRadius: svars.borderRadius,
                }}
                items={productHierarchiesItems}
                onSelectItem={onSelectItem}
                onUnselectItem={onUnselectItem}
                onSelectItems={onSelectItems}
                onResetItems={onResetItems}
                onUnselectItems={onUnselectItems}
                isSelectedItem={isSelectedItem}
                selectedItems={selectedItems}
                placeholder={t({ id: 'search-a-product-service' })}
                searchable
                loading={productHierarchiesLoading || !selectedItems}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {actionDisabled ? (
          <LightText
            style={{
              marginRight: svars.spaceMedium,
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Icon name="exclamation circle" />
            <Trans id="product-service-group.fill-name-and-select-products" />
          </LightText>
        ) : (
          // Make sure the span is always present to keep the layout consistent
          <span />
        )}

        <span>
          <ButtonTransparentDanger
            data-testid="bo-cancel-group-button"
            type="submit"
            onClick={onClose}
          >
            <Trans id="cancel" />
          </ButtonTransparentDanger>

          <FormAction
            loading={productHierarchyGroupsLoading}
            onCreateOrUpdateGroup={onCreateOrUpdateGroup}
            initialGroup={initialGroup}
            disabled={actionDisabled}
          />
        </span>
      </Modal.Actions>
    </Modal>
  );
}

ProductHierarchyGroupCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // If initial group is provided, fill up state with existing group and update it upon
  // CTA click
  initialGroup: commonPropTypes.productHierarchyGroup,
  relatedViewFacetNames: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string })
  ),
};

ProductHierarchyGroupCreateModal.defaultProps = {
  initialGroup: undefined,
  relatedViewFacetNames: [],
};

export default ProductHierarchyGroupCreateModal;
