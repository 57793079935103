import { useMemo } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import {
  addDays,
  differenceInMonths,
  startOfQuarter,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatDate } from 'reducers/locale';
import { getAppConfigValue } from 'selectors/user';

import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import PointerAxis from 'components/ui/PointerAxis';
import DaySelect from 'components/ui/inputs/DaySelect';
import RadioList from 'components/ui/inputs/RadioList';

import commonPropTypes from 'utils/commonPropTypes';
import { startOfHalfYear } from 'utils/date';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${svars.spaceLarge} ${svars.spaceMediumLarge};
`;

export function PeriodRadioHeader({ header, help }) {
  return (
    <Header
      style={{
        fontWeight: 100,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px black solid',
      }}
    >
      <Trans id={header} />
      <HelpTooltip
        position="right center"
        mouseEnterDelay={50}
        mouseLeaveDelay={50}
        help={<Trans id={help} />}
        compact
      />
    </Header>
  );
}

PeriodRadioHeader.propTypes = {
  header: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
};

const getDateFieldErrorState = (minDate, maxDate) =>
  maxDate && minDate && maxDate < minDate;

const getWidthRatio = (minDate, maxDate) =>
  differenceInMonths(maxDate, minDate) / 24;

const getPointerAxisExtraProps = (periodType, period, minDate, maxDate) => {
  const extraProps = {
    middlePointerWidthRatio: 1 / 3,
    secondTickLegend: t({ id: 'today' }),
    isFirstPointerLight: true,
  };
  if (periodType === 'custom') {
    if (minDate) {
      extraProps.isFirstTickLocked = true;
      extraProps.isFirstPointerLight = true;
      extraProps.firstTickLegend = formatDate(minDate, 'P');
      extraProps.middlePointerWidthRatio += getWidthRatio(
        minDate,
        maxDate || new Date()
      );
    }
    if (maxDate) {
      extraProps.isSecondTickLocked = true;
      extraProps.secondTickLegend = formatDate(maxDate, 'P');
    }
  } else if (
    periodType === 'current' ||
    periodType === 'rolling' ||
    periodType === 'complete'
  ) {
    extraProps.isFirstPointerLight = true;
    let minPeriodDate = new Date();
    if (periodType === 'current' && period === '1Y') {
      minPeriodDate = startOfYear(minPeriodDate);
    } else if (periodType === 'current' && period === '3M') {
      minPeriodDate = startOfQuarter(minPeriodDate);
    } else if (periodType === 'current' && period === '6M') {
      minPeriodDate = startOfHalfYear(minPeriodDate);
    } else if (periodType === 'rolling' && period?.includes('M')) {
      const periodLength = parseInt(period.split('M')[0], 10);
      minPeriodDate = addDays(subMonths(minPeriodDate, periodLength), 1);
    } else if (periodType === 'rolling' && period === '1Y') {
      minPeriodDate = addDays(subYears(minPeriodDate, 1), 1);
    } else if (periodType === 'complete') {
      const periodLength = parseInt(period.split('M')[0], 10);
      // Max period day for 'complete' period is always the first day of the previous month
      const maxPeriodDate = new Date(minPeriodDate.setDate(0));
      minPeriodDate = new Date(
        subMonths(maxPeriodDate, periodLength - 1).setDate(1)
      );
      extraProps.secondTickLegend = formatDate(maxPeriodDate, 'P');
    }
    extraProps.firstTickLegend = formatDate(minPeriodDate, 'P');
    extraProps.middlePointerWidthRatio += getWidthRatio(
      minPeriodDate,
      new Date()
    );
  }
  return extraProps;
};

function BasePeriodFieldHelper({
  period,
  periodType,
  periodLengths,
  onPeriodChange,
  minDate,
  maxDate,
  industryFromDate,
  onChangeStartPeriodDatePicker,
  onChangeEndPeriodDatePicker,
}) {
  const extraProps = useMemo(
    () => getPointerAxisExtraProps(periodType, period, minDate, maxDate),
    [periodType, period, minDate, maxDate]
  );
  const errorStatus = getDateFieldErrorState(minDate, maxDate);
  return (
    <Container>
      <Form style={{ marginBottom: svars.spaceLarge, height: '40%' }}>
        {(periodType === 'custom' && (
          <>
            <Form.Field
              style={{
                display: 'flex',

                alignItems: 'center',

                paddingBottom: svars.spaceMedium,
              }}
            >
              <span style={{ width: '100px' }}>
                <Trans id="from" /> ...
              </span>
              <DaySelect
                onSelect={onChangeStartPeriodDatePicker}
                key="min-ds"
                error={errorStatus}
                selected={minDate}
                defaultMonth={minDate}
                placeholder={formatDate(new Date('2022-01-12'), 'P')}
                fromDate={industryFromDate}
              />
            </Form.Field>
            <Form.Field
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '100px' }}>
                <Trans id="until" /> ...
              </span>
              <DaySelect
                style={{ width: 'inherit' }}
                onSelect={onChangeEndPeriodDatePicker}
                key="max-ds"
                error={errorStatus}
                selected={maxDate}
                defaultMonth={maxDate}
                placeholder={formatDate(new Date(), 'P')}
                fromDate={industryFromDate}
              />
              {errorStatus ? (
                <Message
                  style={{ width: '100%' }}
                  error
                  header={t({ id: 'invalid-period' })}
                  content={`${t({
                    id: 'facet-create.select-higher-minimum-date',
                  })} : ${minDate && formatDate(minDate, 'P')}`}
                />
              ) : null}
            </Form.Field>
          </>
        )) ||
          (periodLengths?.length && (
            <div style={{ marginBottom: svars.spaceNormal }}>
              <Trans id="select-period-length" /> :
              <RadioList
                options={periodLengths}
                selected={period}
                onChange={onPeriodChange}
                radioListTestId="bo-period-length-radio-checkbox"
              />
            </div>
          )) ||
          null}
      </Form>
      <PointerAxis
        style={{ margin: 'auto', alignSelf: 'flex-end', height: '60%' }}
        danger={errorStatus}
        title={t({ id: 'analysis-period' })}
        {...extraProps}
      />
    </Container>
  );
}

BasePeriodFieldHelper.propTypes = {
  period: PropTypes.string,
  periodType: PropTypes.string,
  minDate: commonPropTypes.date,
  maxDate: commonPropTypes.date,
  periodLengths: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, commonPropTypes.i18nText]),
    })
  ),
  onPeriodChange: PropTypes.func.isRequired,
  // Minimum aggregation date from app configuration
  industryFromDate: commonPropTypes.date,
  onChangeStartPeriodDatePicker: PropTypes.func.isRequired,
  onChangeEndPeriodDatePicker: PropTypes.func.isRequired,
};
const defaultFromDate = new Date('2015-01-01');
BasePeriodFieldHelper.defaultProps = {
  minDate: null,
  maxDate: null,
  period: null,
  periodType: null,
  periodLengths: null,
  industryFromDate: defaultFromDate,
};

const PeriodFieldHelper = connect((state) => ({
  industryFromDate:
    getAppConfigValue(
      state,
      'MIN_AGGREGATION_DATE',
      (value) => new Date(value)
    ) || defaultFromDate,
}))(BasePeriodFieldHelper);

export default PeriodFieldHelper;
