import { useMemo } from 'react';

import BaseTimeSeriesVisualization from 'components/ui/visualization/BaseTimeSeriesVisualization';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

export function TimeSeries({
  chartId,
  height,
  data,
  dateFormatter,
  meta: { cellKey, indicatorKey, lineIndicatorKey },
}) {
  const [series, axes] = useMemo(() => {
    const tsSeries = [
      {
        key: indicatorKey,
        y_id: 1,
      },
    ];
    const yAxes = {
      1: { orientation: 'left', allowDecimals: false },
    };
    if (lineIndicatorKey) {
      tsSeries.push({
        key: lineIndicatorKey,
        y_id: 2,
      });
      yAxes[2] = { orientation: 'right', allowDecimals: true };
    }
    return [tsSeries, yAxes];
  }, [indicatorKey, lineIndicatorKey]);
  return (
    <BaseTimeSeriesVisualization
      chartId={chartId}
      height={height || 0}
      childrenAttributes={series}
      yAxisProps={axes}
      tooltipFieldKeys={[cellKey, indicatorKey, lineIndicatorKey]}
      dateFormatter={dateFormatter}
      data={data}
    />
  );
}
TimeSeries.propTypes = commonPropTypes.dashboardChart;
TimeSeries.defaultProps = {};
TimeSeries.minHeight = svars.chartHeight;
TimeSeries.minWidth = svars.chartMinWidth;

export default TimeSeries;
