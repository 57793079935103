import { useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import { selectedPresetSelector } from 'store/monitor/campaignPresetSlice';

import { TabButton } from 'components/ui/button/TabButton';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import { WarningLabel } from 'components/ui/inputs/Label';

import * as svars from 'assets/style/variables';

function PresetActionsButton({
  onDisplayPresetEditor,
  onTogglePresets,
  displayPresets,
  disabled,
}) {
  const selectedPreset = useSelector(selectedPresetSelector);
  return selectedPreset ? (
    <StyledDropdown
      direction="left"
      trigger={
        <WarningLabel
          disabled={disabled}
          content={selectedPreset.name}
          color="yellow"
          style={{
            marginRight: svars.spaceNormal,
          }}
        >
          <span
            style={{
              maxWidth: '86px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {selectedPreset.name}
          </span>
          <Icon
            name="ellipsis vertical"
            style={{ margin: 0, marginLeft: svars.spaceNormal }}
          />
        </WarningLabel>
      }
      style={{ minWidth: 0 }}
      icon={null}
      value=""
    >
      <Dropdown.Menu>
        <Dropdown.Item
          key="edit"
          icon="save"
          onClick={() => onDisplayPresetEditor(selectedPreset)}
          content={<Trans id="edit-current-preset" />}
        />
        <Dropdown.Item
          key="copy"
          icon="plus"
          onClick={() => onDisplayPresetEditor(true)}
          content={<Trans id="save-as-copy" />}
        />
        <Dropdown.Item
          key="select"
          icon="list"
          onClick={() => onDisplayPresetEditor(null)}
          content={<Trans id="select-another-preset" />}
        />
      </Dropdown.Menu>
    </StyledDropdown>
  ) : (
    <TabButton
      disabled={disabled}
      icon="bookmark"
      content={<Trans id="saved-presets" />}
      onClick={onTogglePresets}
      active={displayPresets}
      style={{ marginRight: svars.spaceNormal }}
    />
  );
}

PresetActionsButton.propTypes = {
  onDisplayPresetEditor: PropTypes.func,
  onTogglePresets: PropTypes.func,
  displayPresets: PropTypes.bool,
  disabled: PropTypes.bool,
};

PresetActionsButton.defaultProps = {
  onDisplayPresetEditor: () => {},
  onTogglePresets: () => {},
  displayPresets: false,
  disabled: false,
};

export default PresetActionsButton;
