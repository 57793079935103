import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/dist/index';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { questionLabelFormatterSelectorFactory } from 'selectors/survey';

import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import ReactTable from 'components/ui/table/ReactTableUi';

import { removeHtmlTags } from 'utils/helpers';

const MonitorReactTable = styled(ReactTable)`
  max-width: unset;
  box-shadow: unset;
  overflow-y: auto;
  align-content: center;
`;

export default function QuestionsTable({ chartId, data, meta, height }) {
  const { campaignId } = useParams();

  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId)
  );

  const tableData = useMemo(
    () =>
      data.map((question) => {
        const formattedLabels = (Array.isArray(question.value)
          ? question.value
          : [question]
        )
          ?.map(({ id }) => id)
          .map(questionLabelFormatter)
          .map((label) => removeHtmlTags(label)) || ['-'];
        const formattedTitle = formattedLabels.join(', ');

        return {
          ...question,
          title: formattedTitle,
        };
      }),
    [data, questionLabelFormatter]
  );

  const generateColumns = (metadata) =>
    metadata.columns.map((column) => ({
      Header: (
        <ReactTableHeader
          small
          title={column.title}
          helpMessage={column.helpMessage}
        />
      ),
      accessor: column.accessor,
      id: column.id,
      centered: column.centered || false,
    }));

  return (
    <MonitorReactTable
      chartId={chartId}
      data={tableData}
      columns={generateColumns(meta)}
      defaultSorted={meta.defaultSorted}
      style={{ height }}
    />
  );
}

QuestionsTable.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(PropTypes.string, PropTypes.any))
    .isRequired,
  meta: PropTypes.shape({
    columns: PropTypes.arrayOf.isRequired,
    defaultSorted: PropTypes.arrayOf,
  }).isRequired,
  height: PropTypes.number,
};

QuestionsTable.defaultProps = {
  height: null,
};
