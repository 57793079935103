import { msg } from '@lingui/macro';

import {
  createEntityField,
  enrichFieldProps,
  useFormElementsAlternativeDistributionOptions,
} from 'components/customer/campaign/useCampaignFields';
import { reactTableVolumeCellFactory } from 'components/ui/table/cells/ReactTableCell';

import {
  dayFormatter,
  incrementFormatterFactory,
  monthFormatter,
  numberFormatter,
  weekFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';

import * as svars from 'assets/style/variables';

import AlternativeDistribution from './AlternativeDistribution';
import Distribution from './Distribution';
import Kpi, { kpiAccessorFactory } from './Kpi';
import TimeSeries from './TimeSeries';

// Use a method to make sure the translation is not evaluated at import time
const GET_FREQUENCY_OPTIONS = () => [
  { key: 'D', value: 'D', i18nText: msg({ id: 'day' }) },
  { key: 'W', value: 'W', i18nText: msg({ id: 'week' }) },
  { key: 'M', value: 'M', i18nText: msg({ id: 'month' }) },
];

export const makeVolumeColumn = ({ data, meta }) =>
  enrichFieldProps(
    {
      id: meta.indicatorKey,
      label: meta.indicatorLabel,
      accessor: meta.indicatorKey,
      centered: true,
      Cell: reactTableVolumeCellFactory(
        data?.length
          ? Math.max(...(data || []).map((item) => item[meta.indicatorKey]))
          : 0
      ),
    },
    true,
    { isVolume: true }
  );

export const volumeChartConfig = {
  title: msg({ id: 'Volume' }),
  component: Kpi,
  hideMenu: true,
  accessor: kpiAccessorFactory('volume'),
  meta: {
    formatter: numberFormatter,
    increaseFormatter: incrementFormatterFactory(zeroPrecisionPercentFormatter),
  },
};

export const timeSeriesChartConfigFactory = (extraSeries = {}) => {
  const extraTableColumns = extraSeries?.id
    ? [enrichFieldProps(extraSeries, true)]
    : [];
  const extraMeta = extraSeries?.accessor
    ? {
        lineIndicatorKey: extraSeries.accessor,
        lineIndicatorLabel: extraSeries.label,
      }
    : {};
  return {
    title: msg({ id: 'contributions-over-time' }),
    component: TimeSeries,
    accessor: (data) => data.ts,
    meta: {
      cellKey: 'date',
      cellLabel: msg({ id: 'Date' }),
      indicatorKey: 'volume',
      indicatorLabel: msg({ id: 'Volume' }),
      ...extraMeta,
      dataAlternativeOptions: GET_FREQUENCY_OPTIONS(),
      alternativeValueNodeId: 'time-series-select',
      getChartProps: (data, { value }) =>
        value
          ? {
              data: data[value],
              dateFormatter:
                (value === 'M' && monthFormatter) ||
                (value === 'W' && weekFormatter) ||
                dayFormatter,
            }
          : {},
    },
    makeTableData: ({ data, meta }) => ({
      data,
      columns: [
        enrichFieldProps(
          {
            id: meta.cellKey,
            label: meta.cellLabel,
            accessor: meta.cellKey,
          },
          true,
          { isDate: true }
        ),
        makeVolumeColumn({ data, meta }),
        ...extraTableColumns,
      ],
    }),
  };
};

export const channelDistributionChartConfigFactory = (
  channelLabelFormatter,
  colorFormatter
) => ({
  title: msg({ id: 'campaign-channels' }),
  accessor: (data) => data.distributions?.channel,
  component: Distribution,
  meta: {
    cellKey: 'channel',
    cellLabel: msg({ id: 'distribution-channel' }),
    indicatorKey: 'volume',
    indicatorLabel: msg({ id: 'volume' }),
    minColor: svars.minVolumeColor,
    maxColor: svars.volumeStrokeColor,
  },
  formatters: {
    labelFormatter: channelLabelFormatter,
  },
  makeTableData: ({ data, meta }) => ({
    data,
    defaultSorted: [{ id: meta.cellKey, desc: true }],
    columns: [
      createEntityField(
        (item) => item[meta.cellKey],
        {
          id: meta.cellKey,
          label: meta.cellLabel,
          accessor: meta.cellKey,
          centered: false,
        },
        true,
        colorFormatter,
        channelLabelFormatter,
        null,
        null,
        false
      ),
      makeVolumeColumn({ data, meta }),
    ],
  }),
});

export const distributionByChartConfigFactory = (
  accessor,
  cellKeyAccessor,
  alternativeValueNodeId,
  noDataMessage,
  labelFormatter,
  colorFormatter,
  alternativeOptionsSelector,
  alternativeLabelFormatter,
  minColor = null,
  maxColor = null,
  cellLabel = msg({ id: 'tag' }),
  cellKeyLabelWidth = null,
  title = msg({ id: 'distribution-by' })
) => ({
  title,
  noDataMessage,
  component: AlternativeDistribution,
  accessor,
  meta: {
    cellKey: null,
    cellLabel,
    indicatorKey: 'volume',
    indicatorLabel: msg({ id: 'volume' }),
    // Meta properties for alternative charts
    dataAlternativeOptions: alternativeOptionsSelector,
    alternativeValueNodeId,
    minColor,
    maxColor,
    getChartProps: (data, { value, options }, meta) =>
      value
        ? {
            data: data[value],
            meta: {
              ...meta,
              cellKey: cellKeyAccessor(
                options.find((option) => option.value === value)
              ),
            },
          }
        : {},
  },
  formatters: {
    labelFormatter,
    colorFormatter,
  },
  makeTableData: ({ data, meta }) => ({
    data,
    columns: [
      createEntityField(
        (item) => item[meta.cellKey],
        {
          id: meta.cellKey,
          label: alternativeLabelFormatter(meta.cellKey),
          accessor: meta.cellKey,
          width: cellKeyLabelWidth,
        },
        true,
        colorFormatter,
        labelFormatter,
        null,
        null,
        false
      ),
      makeVolumeColumn({ data, meta }),
    ],
  }),
});

export const tagFormsChartConfigFactory = (
  tagLabelFormatter,
  tagSetLabelFormatter,
  tagColorFormatter
) =>
  distributionByChartConfigFactory(
    (data) => data.tag_distributions,
    (item) => item?.value,
    'distribution-select',
    msg({ id: 'form-element-distribution.no-data-message' }),
    (item) => tagLabelFormatter(item),
    tagColorFormatter,
    useFormElementsAlternativeDistributionOptions,
    tagSetLabelFormatter,
    svars.minOntologyColor,
    svars.maxOntologyColor
  );
