import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import { Trans , useLingui } from '@lingui/react';
import styled from 'styled-components';

import {
  useChannelLabelFormatter,
  useConceptLabelFormatter,
} from 'reducers/entityLabelFormatter';
import {
  campaignAclSelectorFactory,
  campaignSelector,
} from 'selectors/campaign';
import {
  tagColorFormatterSelector,
  tagLabelFormatterSelector,
  userLabelFormatterSelector,
} from 'selectors/entities';
import {
  isFeedbackCampaignSelector,
  questionAnswerLabelFormatterSelectorFactory,
  surveyQuestionsItemsSelectorFactory,
} from 'selectors/survey';

import { LimitedRichTextCell } from 'components/ui/Text';
import {
  AnnotationTagCell,
  AnnotationTextCell,
  AnnotationUserCell,
} from 'components/ui/cells/AnnotationCell';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import {
  DateTimeCell,
  DayCell,
  LabelCell,
  MultilineTextCell,
  ReactTableTextCell,
  ReactTableVolumeCell,
  UserLabelCell,
} from 'components/ui/table/cells/ReactTableCell';

import { longDayFormatter } from 'utils/formatter';
import { removeHtmlTags } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const polarizedTagSortMethod =
  (getEntityId, valueFormatter) => (aItem, bItem) => {
    const aValue = valueFormatter(getEntityId(aItem.original));
    const bValue = valueFormatter(getEntityId(bItem.original));
    if (bValue === null) return 1;
    if (aValue === null) return -1;
    if (aValue === bValue) return 0;
    return aValue > bValue ? 1 : -1;
  };

const MultilineLimitedRichTextCell = styled(LimitedRichTextCell)`
  &&&&& {
    white-space: pre-wrap;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`;
export const ParsedHeader = (name, help) =>
  function TableHeader() {
    return (
      <ReactTableHeader small helpMessage={help}>
        <MultilineLimitedRichTextCell value={name} />
      </ReactTableHeader>
    );
  };

export const tableHeaderFactory = (name, help) =>
  function TableHeader() {
    return (
      <ReactTableHeader small helpMessage={help}>
        {/* {capitalize(name)} */}
        <Trans id={name} render={capitalizedTranslation} />
      </ReactTableHeader>
    );
  };
export const useIdentificationForm = 'identificationFormFields';
export const useUrlForm = 'urlFormFields';
export const useAnnotationForm = 'annotationFormFields';
export const useSatisfactionTag = 'satisfactionTagField';
export const useFeedbackForm = 'feedbackFields';
export const useSurveyForm = 'surveyFormFields';

const TAG_FIELD_CONFIGURATION = {
  [useIdentificationForm]: {
    campaignFormFieldName: 'identification_form',
    feedbackFormValuesFieldName: 'respondent_values',
    many: true,
  },
  [useUrlForm]: {
    campaignFormFieldName: 'url_form',
    feedbackFormValuesFieldName: 'url_values',
    many: true,
  },
  [useAnnotationForm]: {
    campaignFormFieldName: 'annotation_form',
    feedbackFormValuesFieldName: 'annotation_values',
    many: true,
  },
  [useSatisfactionTag]: {
    campaignFormFieldName: 'satisfaction_tag',
    feedbackFormValuesFieldName: 'satisfaction_tag',
    many: false,
  },
};
const getEmptyCellProps = () => ({});

export const enrichFieldProps = (
  field,
  withTableProps,
  { isDateTime = false, isDate = false, isVolume = false } = {}
) => ({
  ...field,
  id: field.id || field.key || field.accessor,
  centered: field.centered || false,
  accessor:
    typeof field.accessor === 'string'
      ? (item) => item[field.accessor]
      : field.accessor,
  width: field.width || svars.defaultColumnMinWidth,
  sortType: field.sortType || 'alphanumeric',
  maxWidth:
    field.maxWidth ||
    (isVolume && svars.defaultVolumeColumnMaxWidth) ||
    svars.defaultColumnMaxWidth,
  getCellProps: field.getCellProps || getEmptyCellProps,
  formatter:
    field.formatter || ((isDate || isDateTime) && longDayFormatter) || null,
  Cell:
    field.Cell ||
    (isDate && DayCell) ||
    (isDateTime && DateTimeCell) ||
    (isVolume && ReactTableVolumeCell) ||
    ReactTableTextCell,
  ...(withTableProps
    ? {
        Header: field.Header || tableHeaderFactory(field.label)(),
      }
    : { header: field.label }),
});

const getAnnotationCell = (isEditableTag, isUserAnnotation) =>
  (isUserAnnotation &&
    ((isEditableTag && AnnotationUserCell) || UserLabelCell)) ||
  (isEditableTag && AnnotationTagCell) ||
  LabelCell;
/**
 *
 *
 * @param {*} getEntityId
 * @param {*} { id, label, tag_set, width }
 * @param {*} withTableProps
 * @param {*} tagColorFormatter
 * @param {*} tagLabelFormatter
 * @param {*} feedbackFormValuesFieldName
 * @param {boolean} [isEditableTag=false]
 * @return {*}
 */
export const createEntityField = (
  getEntityId,
  { id, label, tag_set, width, maxWidth, centered, accessor, ...extraProps },
  withTableProps,
  colorFormatter,
  labelFormatter,
  valueFormatter,
  feedbackFormValuesFieldName,
  isEditableTag = false,
  isUserAnnotation = false
) =>
  enrichFieldProps(
    {
      // placing these extra props at first is important to make sure
      // we override
      id,
      key: `entfield-${id}`,
      type: 'tag',
      tagSetId: tag_set?.id,
      label,
      Cell: getAnnotationCell(isEditableTag, isUserAnnotation),

      sortType: valueFormatter
        ? polarizedTagSortMethod(getEntityId, valueFormatter)
        : null,
      width,
      maxWidth,
      centered: centered != null ? centered : true,
      getCellProps: (item) => ({
        color: colorFormatter?.(getEntityId(item)),
      }),
      accessor: getEntityId
        ? // Formatting happens in accessor
          (item) => labelFormatter(getEntityId(item))
        : accessor,
      feedbackFormValuesFieldName,
      ...extraProps,
    },
    withTableProps
  );

const identity = (id) => id;

const getFormElementValuesAccessorFactory =
  (feedbackFormValuesFieldName, formField, callback = identity) =>
  (item) =>
    callback(
      item[feedbackFormValuesFieldName]?.find(
        ({ form_element: { id: formId } }) => formId === formField.id
      )
    );

const surveyQuestionAnswerSelectorFactory =
  (questionId, multi = false) =>
  (item) => {
    const answer = item.answers?.find(
      ({ question: { id: answerQuestionId } }) =>
        questionId === answerQuestionId
    );
    if (multi) {
      return answer?.value && Object.values(answer.value).map(({ id }) => id);
    }
    return answer?.value;
  };
/**
 * Tag campaign fields generator hook.
 * @param {*} param0
 * @param {boolean} [withTableProps=false]
 * @param {boolean} [flatten=true]
 * @param {boolean} [isEditableForm=false]
 * @param {boolean} [isTagOnly=false]
 * @return {*}
 */
export function useCampaignFormFields(
  {
    useIdentificationForm: identificationForm,
    useUrlForm: urlForm,
    useAnnotationForm: annotationForm,
    useSatisfactionTag: satisfactionTag,
    useSurveyForm: surveyForm,
    useSurveyTextQuestions = true,
  },
  withTableProps,
  isEditableForm,
  isTagOnly = false,
  hasWritePermission = true
) {
  const { i18n } = useLingui();
  const { campaignId } = useParams();
  const campaignConfiguration = useSelector(campaignSelector(campaignId));
  const tagLabelFormatter = useSelector(tagLabelFormatterSelector);
  const userLabelFormatter = useSelector(userLabelFormatterSelector);
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const questionAnswerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const fields = useMemo(() => {
    const selectedFields = {};
    // Optionally add satisfaction tag field
    if (satisfactionTag) {
      const { campaignFormFieldName, feedbackFormValuesFieldName } =
        TAG_FIELD_CONFIGURATION[satisfactionTag];
      selectedFields[satisfactionTag] = [
        createEntityField(
          (item) => item[campaignFormFieldName]?.id,
          {
            id: '123',
            label: t({ id: 'satisfaction-level' }),
            tag_set: campaignConfiguration.satisfaction_tag_set,
            width: 200,
          },
          withTableProps,
          tagColorFormatter,
          tagLabelFormatter,
          null,
          feedbackFormValuesFieldName,
          false
        ),
      ];
    }
    // Optionally add survey form fields
    if (surveyForm) {
      selectedFields[surveyForm] = campaignConfiguration.questions
        .map(({ id, title, type }) => {
          const commonProps = {
            id,
            key: `surveyfield-${id}`,
            // label: title,
            label: removeHtmlTags(title),
            maxWidth: 400,
            width: 300,
            centered: false,
          };
          if (type === 'MultiChoiceSurveyQuestion') {
            return createEntityField(
              surveyQuestionAnswerSelectorFactory(id, true),
              {
                ...commonProps,
                Header: ParsedHeader(title),
                Cell: ReactTableTextCell,
              },
              withTableProps,
              tagColorFormatter,
              questionAnswerLabelFormatter,
              null,
              'answers',
              false
            );
          }
          // Text field
          if (type === 'TextSurveyQuestion' && useSurveyTextQuestions) {
            return enrichFieldProps(
              {
                ...commonProps,
                type: 'text',
                Header: ParsedHeader(title),
                Cell: MultilineTextCell,
                accessor: surveyQuestionAnswerSelectorFactory(id, false),
                feedbackFormValuesFieldName: 'answers',
              },
              withTableProps
            );
          }
          return null;
        })
        .filter((item) => item);
    }
    // Add form tags fields
    [identificationForm, urlForm, annotationForm].forEach((formFlag) => {
      if (!formFlag) return;
      const { campaignFormFieldName, feedbackFormValuesFieldName } =
        TAG_FIELD_CONFIGURATION[formFlag];
      selectedFields[formFlag] = campaignConfiguration[campaignFormFieldName]
        .filter(
          ({ form_type }) => !isTagOnly || form_type !== 'InputFormElement'
        )
        .map(({ form_type, ...formField }) => {
          const isEditable =
            formFlag === useAnnotationForm &&
            isEditableForm &&
            hasWritePermission;
          if (form_type === 'MultipleChoiceFormElement') {
            return createEntityField(
              getFormElementValuesAccessorFactory(
                feedbackFormValuesFieldName,
                formField,
                (itemValue) => itemValue?.tag?.id
              ),
              formField,
              withTableProps,
              tagColorFormatter,
              tagLabelFormatter,
              null,
              feedbackFormValuesFieldName,
              isEditable
            );
          }
          if (form_type === 'UserFormElement') {
            return createEntityField(
              getFormElementValuesAccessorFactory(
                feedbackFormValuesFieldName,
                formField,
                (itemValue) => itemValue?.user?.id
              ),
              { ...formField, centered: false, minWidth: 150 },
              withTableProps,
              tagColorFormatter,
              userLabelFormatter,
              null,
              feedbackFormValuesFieldName,
              isEditable,
              true
            );
          }
          const { id, label, width, maxWidth, centered } = formField;
          return enrichFieldProps(
            {
              id,
              type: 'text',
              key: `textfield-${id}`,
              label,
              Cell: (isEditable && AnnotationTextCell) || LabelCell,
              width,
              maxWidth,
              centered: centered || true,
              getCellProps: () => ({}),
              accessor: getFormElementValuesAccessorFactory(
                feedbackFormValuesFieldName,
                formField,
                (itemValue) => itemValue?.value
              ),
              feedbackFormValuesFieldName,
            },
            withTableProps
          );
        });
    });

    return selectedFields;
  }, [campaignConfiguration, i18n.locale]);
  return fields;
}
useCampaignFormFields.defaultProps = { tags: {} };

function useCampaignFields(
  withTableProps = false,
  flatten = true,
  isEditableForm = false
) {
  const { i18n } = useLingui();
  const { campaignId } = useParams();

  const conceptLabelFormatter = useConceptLabelFormatter();
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)(
    (item) => item?.channel?.id
  );

  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const campaignConfiguration = useSelector(campaignSelector(campaignId));
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );

  const {
    identificationFormFields,
    urlFormFields,
    annotationFormFields,
    satisfactionTagField,
    surveyFormFields,
  } = useCampaignFormFields(
    {
      useIdentificationForm,
      useUrlForm,
      useAnnotationForm,
      ...(isFeedbackCampaign ? { useSatisfactionTag } : { useSurveyForm }),
    },
    withTableProps,
    isEditableForm,
    false,
    acl?.w
  );

  const fields = useMemo(() => {
    const dateFieldProps = enrichFieldProps(
      {
        key: 'date',
        type: 'date',
        label: t({ id: 'date' }),
        width: 60,
        getCellProps: () => ({ inline: !withTableProps }),
        accessor: (item) => item.date,
      },
      withTableProps,
      { isDateTime: true }
    );
    const linkFieldProps = enrichFieldProps(
      {
        key: 'link',
        type: 'link',
        label: t`link`,
        width: 60,
        getCellProps: () => ({ inline: !withTableProps }),
        accessor: channelLabelFormatter,
      },
      withTableProps
    );
    const fieldsMap = isFeedbackCampaign
      ? {
          date: [dateFieldProps],
          linkFieldProps,
          feedbackFields: [
            enrichFieldProps(
              {
                key: 'concept',
                type: 'concept',
                label: t({ id: 'category' }),
                accessor: (item) => conceptLabelFormatter(item.concept?.id),
                width: 80,
                centered: false,
              },
              withTableProps
            ),
          ],
          texts: [
            enrichFieldProps(
              {
                key: 'text',
                type: 'text',
                label: withTableProps ? t({ id: 'text' }) : null,
                accessor: (item) => item.text || '-',
                Cell: MultilineTextCell,
                width: 250,
              },
              withTableProps
            ),
          ],
          satisfactionTagField,
          identificationFormFields,
          urlFormFields,
          annotationFormFields,
        }
      : {
          date: [dateFieldProps],
          link: [linkFieldProps],
          identificationFormFields,
          urlFormFields,
          annotationFormFields,
          surveyFormFields,
        };
    return flatten
      ? Object.values(fieldsMap).flatMap((item) => item)
      : fieldsMap;
  }, [campaignConfiguration, i18n.locale]);
  return fields;
}

export const useFormElementsAlternativeDistributionOptions = () => {
  const formFields = useCampaignFormFields(
    {
      useIdentificationForm,
      useUrlForm,
      useAnnotationForm,
    },
    false,
    false,
    true
  );
  const options = useMemo(() =>
    Object.values(formFields)
      .flatMap((item) => item)
      .map(({ label, id, tagSetId }) => ({
        key: id,
        value: id,
        text: label,
        tagSetId,
      }))
  );
  return options;
};

export const useSurveyQuestionsAlternativeDistributionOptions = () => {
  const { campaignId } = useParams();
  const questionsItems = useSelector(
    surveyQuestionsItemsSelectorFactory(campaignId, false, true)
  );
  return questionsItems;
};

export default useCampaignFields;
